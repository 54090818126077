/**
 * Created by vladislav on 03.03.2020
 */

var BeforeLoseWindow = CleverappsWindow.extend({
    onWindowLoaded: function (game) {
        this.game = game;

        this._super({
            name: "loseroundwindow",
            title: "LoseRoundWindow.title",
            content: this.getContent(),
            button: {
                text: "OK",
                onClick: function () {
                    this.close();
                }.bind(this)
            }
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.LoseRoundWindow;

        var roundResults = new RoundResultsComponent(cleverapps.meta.getRumble().getCurrentRound());

        var list = new WordsListComponent({
            words: this.game.board.getUndiscoveredWords(),
            width: styles.list.width,
            height: styles.list.height,
            minimalWordsAmount: 8
        });

        var text = cleverapps.UI.generateTTFText("BeforeLoseWindow.words", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(styles.text.width);

        var wordsLayout = new cleverapps.Layout([text, list], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.text.margin
        });

        return new cleverapps.Layout([roundResults, wordsLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    }
});

cleverapps.styles.LoseRoundWindow = {
    margin: 60,

    text: {
        width: 500,
        margin: 10
    },

    padding: {
        top: 40
    },

    list: {
        height: 306,
        width: 300
    },

    roundResults: {
        x: { align: "center" },
        y: { align: "center" }
    }
};