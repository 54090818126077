/**
 * Created by mac on 9/22/22
 */

var ShortMetaProgress = function () {
    this.levels = this.createLevels();
};

ShortMetaProgress.prototype.getLevels = function () {
    return this.levels;
};

ShortMetaProgress.prototype.createLevels = function () {
    var level = cleverapps.user.getVirtualProgress();
    var start = Math.floor(level / 10) * 10;

    var res = [];
    for (var i = 0; i < 10; i++) {
        res.push(new ShortMetaLevel(i + start));
    }
    return res;
};