/**
 * Created by vladislav on 19.02.2020
 */

var KnockoutPlayerView = cc.Node.extend({
    ctor: function (player) {
        this._super();

        this.player = player;
        this.pos = player.player ? "left" : "right";

        this.openedPoints = {};

        this.setAnchorPoint(0.5, 0.5);
        this.createBg();
        this.createAvatar();
        this.createScore();
        this.createPoints();
        this.addFrame();
        this.updateSize();
        this.setupChildren();

        if (this.player.savedPoints) {
            this.player.savedPoints.forEach(function (index) {
                this.openScorePoint(true, index);
            }.bind(this));
        }

        if (cleverapps.config.debugMode) {
            this.clickRect = cc.rect(this.pos === "left" ? 0 : this.width / 2, 0, this.width / 2, this.height);

            cleverapps.UI.onClick(this, function () {
                if (cleverapps.forces.isRunningForce()) {
                    return;
                }

                if (Game.currentGame) {
                    Game.currentGame.discoverDebug(this.player);
                }
            }.bind(this));
        }
    },

    updateSize: function () {
        this.setContentSize2(this.getSize());
        var styles = cleverapps.styles.KnockoutPlayerView;

        this.bg && this.bg.setContentSize2(this.width, this.bg.height);
        this.avatar.setScale(styles.avatar.scale);
        this.frame && this.frame.setScale(this.avatar.scale);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.KnockoutPlayerView;

        this.bg && this.bg.setPositionRound(this.width / 2, this.height / 2);
        this.avatar.setPositionRound(styles.avatar[this.pos]);
        this.score && this.score.setPositionRound(styles.score[this.pos]);
        this.pointsLayout.setPositionRound(styles.points[this.pos]);
        this.frame && this.frame.setPositionRound(this.avatar.getPosition());
        this.place && this.place.setPositionRound(cleverapps.styles.KnockoutPlayerView.place[this.pos]);
        this.placeIcon && this.placeIcon.setPositionRound(cleverapps.styles.KnockoutPlayerView.place.icon[this.pos]);
    },

    getSize: function () {
        return cleverapps.styles.KnockoutPlayerView.size;
    },

    createBg: function () {},

    addFrame: function () {
        this.frame = new cc.Sprite(bundles.main.frames.frame_pulsation);
        this.frame.setCascadeColorEnabled(true);
        this.addChild(this.frame);
        this.frame.setLocalZOrder(-1);
        this.frame.setVisible(false);
    },

    checkPlayerPointOpened: function () {
        this.animateScore();
        this.openScorePoint();
    },

    animateScore: function () {
        if (!this.score) {
            return;
        }

        this.score.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.RotateTo(0.15, -15),
                new cc.RotateTo(0.15, 15),
                new cc.RotateTo(0.15, -8),
                new cc.RotateTo(0.15, 8),
                new cc.RotateTo(0.1, 0)
            ),
            new cc.Sequence(
                new cc.ScaleTo(0.1, 1.2 * this.score.baseScale),
                new cc.ScaleTo(0.5, 1.0 * this.score.baseScale).easing(cc.easeIn(4))
            ),
            new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.CallFunc(function () {
                    this.score.setString(this.player.amount);
                }.bind(this))
            )
        ));
    },

    getScoreItemIndex: function () {
        var index = this.player.amount - 1, i;

        if (this.player.id === connector.platform.getUserID()) {
            if (cleverapps.config.type === "board") {
                for (i = 0; i < Game.currentGame.board.words.length; i++) {
                    var word = Game.currentGame.board.words[i];
                    if (Game.currentGame.board.foundWordsSet[word] !== undefined) {
                        if (this.openedPoints[i] === undefined) {
                            index = i;
                            break;
                        }
                    }
                }
            } else {
                for (i = 0; i < Game.currentGame.areas.length; i++) {
                    var area = Game.currentGame.areas[i];
                    if (area.discovered && this.openedPoints[i] === undefined) {
                        index = i;
                        break;
                    }
                }
            }
        } else {
            var variants = [];

            if (cleverapps.Random.nextDouble() < 0.8) {
                for (i = 0; i < Game.currentGame.totalItemsToSolve(); i++) {
                    if (this.openedPoints[i] === undefined && this.opponentView.openedPoints[i] !== undefined) {
                        variants.push(i);
                    }
                }
            }

            if (!variants.length) {
                for (i = 0; i < Game.currentGame.totalItemsToSolve(); i++) {
                    if (this.openedPoints[i] === undefined) {
                        variants.push(i);
                    }
                }
            }

            if (variants.length) {
                index = cleverapps.Random.choose(variants);
                cleverapps.audio.playSound(bundles.game.urls.enemy_point);
            }
        }

        return index;
    },

    openScorePoint: function (silent, pointIndex) {
        var index = pointIndex || this.getScoreItemIndex();

        var image = new cc.Sprite(this.player.player ? bundles.game.frames.point_opened_png : bundles.game.frames.point_opponent_opened_png);
        this.openedPoints[index] = image;

        this.points[index].addChild(image);
        image.setPositionRound(this.points[index].width / 2, this.points[index].height / 2);

        if (silent) {
            return;
        }

        image.setScale(0.5);
        image.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.3),
            new cc.ScaleTo(0.2, 1.0)
        ));
    },

    createPoints: function () {
        var styles = cleverapps.styles.KnockoutPlayerView;

        this.points = [];
        for (var i = 0; i < Game.currentGame.totalItemsToSolve(); i++) {
            this.points.push(new cc.Sprite(styles.points[this.pos].image));
        }

        this.pointsLayout = new cleverapps.Layout(this.points, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.points.margin
        });

        this.addChild(this.pointsLayout);
        var scale = styles.points.maxWidth / this.pointsLayout.width;
        if (scale < 1) {
            this.pointsLayout.setScale(scale);
        }

        cleverapps.tooltipManager.create(this.pointsLayout, {
            text: Messages.get(this.player.player ? "KnockoutYouTooltip" : "KnockoutEnemyTooltip"),
            location: cleverapps.UI.Tooltip.LOCATION_BELOW
        });
    },

    createScore: function () {
        var styles = cleverapps.styles.KnockoutPlayerView.score;

        if (!styles) {
            return;
        }

        var font = cleverapps.styles.FONTS.BOT_SCORE;
        if (this.player.player || cleverapps.meta.getRumble().getCurrentRound().isLast()) {
            font = cleverapps.styles.FONTS.PLAYER_SCORE;
        }
        this.score = cleverapps.UI.generateImageText(this.player.amount, font);
        this.score.baseScale = this.score.scale;
        this.addChild(this.score);
    },

    createAvatar: function () {
        this.avatar = new cleverapps.UI.Avatar(this.player);
        this.addChild(this.avatar);
        this.avatar.setLocalZOrder(10);
    },

    runForceWordsAnimation: function (f) {
        this.points.forEach(function (point, index) {
            this.points[index].runAction(new cc.Sequence(
                new cc.DelayTime(index * 0.12),
                new cc.ScaleTo(0.12, 1.2),
                new cc.ScaleTo(0.12, 1.0),
                new cc.CallFunc(function () {
                    if (index === this.points.length - 1) {
                        f();
                    }
                }.bind(this))
            ));
        }.bind(this));
    },

    runForceAvatarAnimation: function (callback) {
        var baseScale = this.avatar.getScale();
        this.avatar.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.RotateTo(0.15, -10),
                new cc.RotateTo(0.15, 10),
                new cc.RotateTo(0.15, -5),
                new cc.RotateTo(0.15, 5),
                new cc.RotateTo(0.1, 0),
                new cc.CallFunc(callback)
            ),
            new cc.Sequence(
                new cc.ScaleTo(0.4, baseScale * 1.3),
                new cc.ScaleTo(0.4, baseScale * 1.0)
            )
        ));
    },

    getOpenedPoints: function () {
        return Object.keys(this.openedPoints);
    },

    runUserBehindAnimation: function () {
        if (this.userBehindRunning) {
            return;
        }
        if (cleverapps.forces.isRunningForce()) {
            return;
        }

        this.userBehindRunning = true;

        this._runFrameAnimation();
    },

    _runFrameAnimation: function () {
        if (!this.frame) {
            return;
        }

        if (!this.userBehindRunning) {
            this.frame.setVisible(false);
            return;
        }

        this.frame.setVisible(true);
        this.frame.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.4, 1.15 * this.frame.getScale()),
                new cc.ScaleTo(0.4, this.frame.getScale()),
                new cc.CallFunc(this._runFrameAnimation.bind(this))
            )
        );
    },

    stopUserBehindAnimation: function () {
        this.userBehindRunning = false;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BOT_SCORE: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    PLAYER_SCORE: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.BLACK
    }
});

cleverapps.styles.KnockoutPlayerView = {
    size: {
        width: 990,
        height: 125
    },

    avatar: {
        scale: 0.7,
        "left": {
            x: { align: "left", dx: 170 },
            y: { align: "center" }
        },
        "right": {
            x: { align: "right", dx: -170 },
            y: { align: "center" }
        }
    },

    points: {
        margin: 7,
        maxWidth: 350,
        "left": {
            image: bundles.game.frames.word_closed_user_png,
            x: { align: "center" },
            y: { align: "top", dy: -25 }
        },
        "right": {
            image: bundles.game.frames.word_closed_opponent_png,
            x: { align: "center" },
            y: { align: "bottom", dy: 8 }
        }
    },

    score: {
        "left": {
            x: { align: "left", dx: 255 },
            y: { align: "center" }
        },
        "right": {
            x: { align: "right", dx: -255 },
            y: { align: "center" }
        }
    }
};