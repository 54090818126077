var KnockoutRulesWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.KnockoutRulesWindow;
        this._super({
            title: "KnockoutRulesWindow.title",
            name: "knockoutruleswindow",
            content: this.getContent(),
            button: {
                text: "OK",
                onPressed: this.close.bind(this),
                width: styles.button.width,
                height: styles.button.height
            }
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.KnockoutRulesWindow;

        var cup = new cc.Node();
        cup.setAnchorPoint(0.5, 0.5);
        cup.setContentSize2(styles.cup);

        var cupAnimation = new cleverapps.Spine(bundles.knockout_cup.jsons.cup_json);
        cupAnimation.setScale(styles.cup.scale || 1.0);
        cup.addChild(cupAnimation);
        cupAnimation.setPositionRound(cup.width / 2, cup.height / 2);
        cupAnimation.setAnimation(0, "animation", true);

        var text = cleverapps.UI.generateTTFText("KnockoutRulesWindow.text", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setDimensions(styles.text.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var rewards = [
            {
                text: "KnockoutRulesWindow.firstPlace",
                reward: 3000
            },
            {
                text: "KnockoutRulesWindow.secondPlace",
                reward: 2000
            },
            {
                text: "KnockoutRulesWindow.thirdPlace",
                reward: 1000
            },
            {
                text: "KnockoutRulesWindow.fourthPlace",
                reward: 200
            }
        ];

        var rows = rewards.map(function (data) {
            var bg = new cc.Scale9Sprite(bundles.table.frames.row_player_png);
            bg.setContentSize2(styles.row);

            var amountBg = new cc.Scale9Sprite(bundles.table.frames.table_bg_png);
            amountBg.setContentSize2(styles.row.amountBg);
            bg.addChild(amountBg);
            amountBg.setPositionRound(styles.row.amountBg);

            var amountText = cleverapps.UI.generateImageText(data.reward, cleverapps.styles.FONTS.KNOCKOUT_RULES_AMOUNT_TEXT);
            amountBg.addChild(amountText);
            amountText.setPositionRound(styles.row.amountText);

            var icon = new cc.Sprite(bundles.reward_icons.frames.gold_small_png);
            amountBg.addChild(icon);
            icon.setPositionRound(styles.row.icon);
            cleverapps.UI.fitToBox(icon, {
                height: amountBg.height - styles.row.icon.padding.y * 2
            });

            var placeText = cleverapps.UI.generateOnlyText(data.text, cleverapps.styles.FONTS.WINDOW_TEXT);
            bg.addChild(placeText);
            placeText.setPositionRound(styles.row.placeText);

            return bg;
        });

        var layout = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.rowMargin
        });

        var bg = new cc.Scale9Sprite(bundles.table.frames.table_bg_png);
        bg.setContentSize2(layout.width + 2 * styles.padding.x, layout.height + 2 * styles.padding.y);
        bg.addChild(layout);
        layout.setPositionRound(bg.width / 2, bg.height / 2);

        return new cleverapps.Layout([cup, text, bg], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.contentPadding
        });
    },

    listBundles: function () {
        return ["battle"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    KNOCKOUT_RULES_AMOUNT_TEXT: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.KnockoutRulesWindow = {
    contentPadding: { x: 0, y: 0 },
    button: {
        width: 200,
        height: 100
    },
    text: {
        width: 550
    },
    cup: {
        width: 300,
        height: 300,
        scale: 0.7
    },
    margin: 20,
    rowMargin: 5,
    padding: {
        x: 5,
        y: 5
    },
    row: {
        width: 600,
        height: 80,
        amountBg: {
            width: 180,
            height: 65,
            x: { align: "right", dx: -8 },
            y: { align: "center" }
        },
        amountText: {
            x: { align: "left", dx: 10 },
            y: { align: "center" }
        },
        icon: {
            x: { align: "right", dx: -3 },
            y: { align: "center" },
            padding: {
                y: 5
            }
        },
        placeText: {
            x: { align: "left", dx: 20 },
            y: { align: "center" }
        }
    }
};