/**
 * Created by vladislav on 12.05.2020
 */

var KnockoutVictoryWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.KnockoutVictoryWindow;

        this._super({
            name: "knockoutvictorywindow",
            title: "VictoryWindow.Victory",
            content: this.getContent(),
            shareId: "tournament",
            styles: cleverapps.styles.KnockoutVictoryWindow.window,
            closeButton: false,
            fireworks: true,
            button: {
                text: "OK",
                width: styles.button.width,
                height: styles.button.height,
                onPressed: function () {
                    this.close();
                }.bind(this)
            }
        });

        this.fadeIn();
    },

    getPerson: function () {
        return {
            role: "hero",
            emotion: "happy"
        };
    },

    onShow: function () {
        this._super();

        cleverapps.scenes.getRunningScene().knockoutVictoryCup.replaceParentSamePlace(this.content);
    },

    fadeIn: function () {
        this.buttons.setOpacity(0);
        this.buttons.runAction(new cc.FadeIn(0.3));
    },

    getContent: function () {
        var styles = cleverapps.styles.KnockoutVictoryWindow;

        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setContentSize2(styles);

        return this.content;
    },

    beforeCloseAnimation: function (callback) {
        if (this.fireworks) {
            this.fireworks.removeFromParent();
            this.fireworks = undefined;
        }

        callback();
    }
});

cleverapps.styles.KnockoutVictoryWindow = {
    width: 400,
    height: 800,
    button: {
        width: 260,
        height: 100,
        x: { align: "center" },
        y: { align: "bottom", dy: 100 }
    },
    window: {
        windowShowUpAnimation: {
            force: true,
            name: "instant"
        },
        noBackground: true
    }
};
