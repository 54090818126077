/**
 * Created by vladislav on 06/12/2022
 */

var ShortMetaLevel = function (levelNo) {
    this.levelNo = levelNo;

    var currentLevel = cleverapps.user.getVirtualProgress();
    if (levelNo < currentLevel) {
        this.type = Level.TYPE_PASSED;
    } else if (levelNo === currentLevel) {
        this.type = Level.TYPE_CURRENT;
    } else {
        this.type = Level.TYPE_NOTPASSED;
    }
};

ShortMetaLevel.prototype.onPressed = function () {
    cleverapps.meta.display({
        focus: "LevelPressedPlay",
        action: function (f) {
            cleverapps.meta.wantsToPlay(f);
        }
    });
};

ShortMetaLevel.prototype.isInteractive = function () {
    return this.type === Level.TYPE_CURRENT || this.type === Level.TYPE_NOTPASSED;
};

ShortMetaLevel.prototype.isClickable = function () {
    return this.type === Level.TYPE_CURRENT;
};

ShortMetaLevel.prototype.isHard = function () {};

ShortMetaLevel.prototype.isBonus = function () {};

ShortMetaLevel.prototype.getHumanReadableNumber = function () {
    return this.levelNo + 1;
};

ShortMetaLevel.prototype.getReward = function () {
    return cleverapps.shortmeta.getReward(this.levelNo);
};