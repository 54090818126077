var KnockoutTutorial = function () {
    this.step = 0;
    this.control = undefined;

    this.onPlayersForceListener = function () {};
    this.onFirstMoveForceListener = function () {};
};

KnockoutTutorial.prototype.showPlayButtonForce = function (f) {
    cleverapps.playButton.showForce(f);
};

KnockoutTutorial.prototype.run = function (f) {
    if (KnockoutTutorial.STEPS[this.step].isShown()) {
        f();
    } else {
        KnockoutTutorial.STEPS[this.step].show(f);
    }
    this.step++;
};

KnockoutTutorial.prototype.isAvailable = function () {
    for (var step = this.step; step < KnockoutTutorial.STEPS.length; step++) {
        if (!KnockoutTutorial.STEPS[step].isShown()) {
            this.control = KnockoutTutorial.STEPS[step].control;
            return KnockoutTutorial.STEPS[step].isAvailable();
        }
    }
    return false;
};

KnockoutTutorial.STEPS = [
    {
        isShown: function () {
            return cleverapps.forces.isShown(Forces.METHA_PLAY_BUTTON.id);
        },
        isAvailable: function () {
            return cleverapps.environment.isMainScene();
        },
        show: function (f) {
            cleverapps.knockoutTutorial.showPlayButtonForce(f);
        }
    },
    {
        isShown: function () {
            return cleverapps.forces.isShown(Forces.KNOCKOUT_PLAYERS.id);
        },
        isAvailable: function () {
            return cleverapps.environment.isGameScene() && cleverapps.isRumble();
        },
        show: function (f) {
            cleverapps.knockoutTutorial.onPlayersForceListener(f);
        }
    },
    {
        isShown: function () {
            return cleverapps.forces.isShown(Forces.KEYPAD.id);
        },
        control: "finger_tip",
        isAvailable: function () {
            return cleverapps.environment.isGameScene();
        },
        show: function (f) {
            if (Game.currentGame) {
                cleverapps.knockoutTutorial.onFirstMoveForceListener(f);
            } else {
                f();
            }
        }
    }
];
