/**
 * Created by vladislav on 20.02.2020
 */

var WeeklyCupRow = cleverapps.Row.extend({
    getRewards: function () {
        var rewards = cleverapps.weeklyCup.listRewards();
        return rewards[this.currentPlace - 1];
    },

    createPrizeIcon: function (place) {
        return cleverapps.DailyCupRow.choosePrizeIcon(cleverapps.weeklyCup, place);
    },

    createDataContent: function (params) {
        if (!params.dataIcon) {
            params.dataIcon = bundles.tablerow_icons.frames.weeklycup;
        }

        return this._super(params);
    }
});