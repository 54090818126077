/**
 * Created by vladislav on 30.04.20.
 */

var WeeklyCupVictoryWindow = CleverappsWindow.extend({
    onWindowLoaded: function (place) {
        this.place = place;

        this._super({
            title: this.getTitle(),
            name: "weeklycupvictorywindow",
            content: this.getContent(),
            shareId: this.place < 3 && "tournament",
            button: {
                width: cleverapps.styles.WeeklyCupVictoryWindow.button.width,
                height: cleverapps.styles.WeeklyCupVictoryWindow.button.height,
                text: "OK",
                onPressed: this.close.bind(this)
            }
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.WeeklyCupVictoryWindow;

        var cup = new cleverapps.Spine(bundles.knockout_cup.jsons.cup_json);
        cup.setAnimation(0, "animation", true);
        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles.animation);
        container.addChild(cup);
        cup.setPositionRound(container.width / 2, container.height / 2);

        var text = cleverapps.UI.generateTTFText("WeeklyCupVictoryWindow.text", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);

        return new cleverapps.Layout([container, text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    getTitle: function () {
        return "WeeklyCupVictoryWindow.place" + this.place;
    },

    listBundles: function () {
        return ["battle"];
    }
});

cleverapps.styles.WeeklyCupVictoryWindow = {
    margin: 50,

    button: {
        width: 180,
        height: 90
    },

    animation: {
        width: 360,
        height: 400
    },

    text: {
        width: 400
    },

    padding: {
        x: 15,
        y: 15
    }
};