/**
 * Created by vladislav on 17.02.2020
 */

var GridPlayerNameView = cc.Node.extend({
    ctor: function (name, index) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        name = GridPlayerNameView.FormatName(name);

        this.text = cleverapps.UI.generateOnlyText(name, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        this.addChild(this.text);

        this.setContentSize2(this.text);
        this.text.setPositionRound(this.width / 2, this.height / 2);
        this.text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        this.setCascadeOpacityEnabled(true);
        this.setCascadeColorEnabled(true);

        var dir = (index < 4) ? 1 : -1;
        this.setPositionRound({
            x: { align: "center" },
            y: { align: "center", dy: dir * cleverapps.styles.GridPlayerNameView.offsetY }
        });
    },

    runStartAnimation: function (delay) {
        this.setVisible(false);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.Show()
        ));
    },

    runLoseAnimation: function (delay, silent) {
        var color = new cc.Color(120, 120, 120, 255);
        if (silent) {
            this.setColor(color);
        } else {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.TintTo(0.5, color.r, color.g, color.b)
            ));
        }
    },

    setLost: function () {
        this.setColor(new cc.Color(120, 120, 120, 255));
    },

    fadeOut: function () {
        this.runAction(new cc.FadeOut(0.5));
    }
});

GridPlayerNameView.FormatName = function (name) {
    var MAX_LENGTH = 8;
    name = Messages.get(name);
    if (name.length > MAX_LENGTH) {
        name = name.substr(0, MAX_LENGTH - 2) + "...";
    }
    return name;
};

cleverapps.styles.GridPlayerNameView = {
    offsetY: 75
};